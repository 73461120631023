import request from "./request";

// 企业开户
export const insertWalletInfo = (data) =>
  request({
    url: "/client/transaction/updateWalletInfo",
    method: "post",
    data,
  });

// 修改银行卡
export const reviseWalletInfo = (data) =>
  request({
    url: "/client/transaction/reviseWalletInfo",
    method: "post",
    data,
  });

//充值
export const recharge = (data) =>
  request({ url: "/client/transaction/recharge", method: "post", data });

//交易记录
export const transactionList = (params) =>
  request({
    url: "/client/transaction/transactionList",
    method: "get",
    params,
  });
//冻结明细
export const depositList = (params) =>
  request({ url: "/client/transaction/depositList", method: "get", params });

//获取最新交易余额
export const balance = (params) =>
  request({ url: "/client/transaction/balance", method: "get", params });

//立即提现回显
export const withdrawal = (params) =>
  request({ url: "/client/transaction/withdrawal", method: "get", params });

//立即提现回显
export const withdrawalApplication = (data) =>
  request({
    url: "/client/transaction/withdrawalApplication",
    method: "post",
    data,
  });

// 重置密码
export const updatePaymentPwd = (data) =>
  request({ url: "/client/transaction/updatePaymentPwd", method: "put", data });

//查询企业内员工
export const memberList = (params) =>
  request({ url: "/client/user/memberList", method: "get", params });

//权限列表
export const getUserPermissions = (params) =>
  request({
    url: "/client/transaction/getUserPermissions",
    method: "get",
    params,
  });

// 增改权限
export const addUserPermissions = (data) =>
  request({
    url: "/client/transaction/addUserPermissions",
    method: "post",
    data,
  });

// 删除权限
export const removeUserPermissions = (data) =>
  request({
    url: "/client/transaction/removeUserPermissions",
    method: "post",
    data,
  });

//发票列表
export const Invoices = () =>
  request({ url: "/client/transaction/getExpress", method: "get" });

// 企业资质查询
export const getAuthenticationlist = (params) =>
  request({
    url: "/system/manuQualifications/getAuthenticationlist",
    method: "get",
    params,
  });

// 企业资质新增
export const insertManuQualifications = (data) =>
  request({
    url: "/system/manuQualifications ",
    method: "post",
    data,
  });
